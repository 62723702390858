import Navbar from "./Navbar"
import Home from "./Home"
// import ClassPhotos from "./AlgoEasy/ClassPhotos";

function App() {
  // const title = "Welcome to the Revolution of the Mind";

  // const objection = {
  //   walla: "bee",
  //   times: 5
  // }

  // let value = 15
  // let redShirtHeights = [2, 3, 4, 5, 6]
  // let blueShirtHeights = [1, 2, 3, 4, 5]

  return (
    <div className="App">
      <Navbar title="Murrkle" />
      <div className="content">
        {/* {value} */}
        <Home />
        {/* <ClassPhotos
          redShirtHeights={redShirtHeights}
          blueShirtHeights={blueShirtHeights}
        /> */}
      </div>
    </div>
  )
}

export default App
