import { useState } from "react"

const Home = () => {
  const [name, setName] = useState("mario")

  const handleClick = e => {
    if (name === "mario") {
      setName("luigi")
    } else {
      setName("mario")
    }
  }

  const handleClickAgain = name => {
    console.log("hello " + name)
  }
  return (
    <div className="home">
      <h2>Homepage</h2>
      <button onClick={handleClick}>Click me</button>
      <button onClick={() => handleClickAgain(name)}>Click me again</button>
      <h5>Hello, {name}</h5>
    </div>
  )
}

export default Home
